import { Inject, Injectable } from '@angular/core';

import { ApiService } from '../api.service';
import { contains } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { cloneDeep, get, isEmpty } from 'lodash';
import { ConfigurationV2 } from 'lib/services/configuration/configurations-v2.class';
import { ConfigurationDataV2 } from 'lib/services/configuration/configuration-v2.interface';
import { ConfigurationProduct } from 'lib/services/configuration-product/configuration-product.class';
import { ConfigurationProductData, ConfigurationProductResponse } from 'lib/services/configuration-product/configuration-product.interface';
import { ConfigurationProductLine } from 'lib/services/configuration-product-line/configuration-product-line.class';
import {
    ConfigurationProductLineData,
    ConfigurationProductLineResponse,
} from 'lib/services/configuration-product-line/configuration-product-line.interface';
import { ConfigurationConnectorTypeData } from 'lib/services/configuration/configuration-connector-type.interface';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'configurator.service';

let apiService: ApiService;
let routerService: RouterService;

@Injectable()
export class ConfiguratorService {
    configSuffixBlacklist: string[] = ['-SMD'];

    constructor(@Inject('environment') e: any, a: ApiService, r: RouterService) {
        try {
            apiService = a;
            routerService = r;
            this.init();
        } catch (err) {
            console.error(...new ExxComError(937763, scriptName, err).stamp());
        }
    }

    async init() {}

    get router() {
        return routerService.router;
    }

    /**
     * @function getConfigurationByUrlComponent
     * @description gets a list of configuration products with several options
     * @param site either 'exx' or 'spc'
     * @param mpn used for partial matching on mpn when filtering configurations
     * @param fields used for limiting returned fields (ex: "field1 field2 field3")
     * @param limit used for limited total results, expects an integer
     * @param subset used specifically for configurator search page, returns reduced config objects for fast page speed
     * @param replaceRef if true, replaces referenced IDs with their actual data object
     * @returns array of configuration objects
     */
    async getConfigurationByUrlComponent(
        site?: 'exx' | 'spc',
        mpn?: string,
        fields: string = '',
        limit?: number,
        subset?: boolean,
        replaceRef?: boolean
    ) {
        try {
            if (mpn) {
                if (mpn.indexOf('/') == 0) {
                    mpn = mpn.slice(1);
                }
            }

            let getConfigsEndpoint = 'configurations/find' + (site || mpn || fields || limit || subset || replaceRef ? '?' : '');
            getConfigsEndpoint += site ? `site=${site}&` : '';
            getConfigsEndpoint += mpn ? `filters={"mpn": "${mpn}"}&` : '';
            getConfigsEndpoint += fields ? `fields=${fields}&` : '';
            getConfigsEndpoint += limit ? `limit=${limit}&` : '';
            getConfigsEndpoint += subset ? `subset=${subset}&` : '';
            getConfigsEndpoint += replaceRef ? `replaceRef=${replaceRef}` : '';

            const res = await apiService.get([getConfigsEndpoint]);

            if (!res.success) {
                return null;
            } else if (isEmpty(res.data)) {
                return null;
            } else {
                const arrData = [];
                for (const data of res.data) {
                    arrData.push(new ConfigurationV2(data));
                }

                return arrData;
            }
        } catch (err) {
            console.error(...new ExxComError(930920, scriptName, err).stamp());
        }
    }

    async getConfigurationProduct(type: string, name: string, isProductComponent?: boolean) {
        try {
            if (name.indexOf('/') == 0) {
                name = name.slice(1);
            }

            const res = await apiService.get([
                'configurations/products/find',
                `?filters={
              "name": "${name}", 
              "type": "${type}"
        }
        &limit=1`,
            ]);

            if (!res.success) {
                throw res;
            } else if (isEmpty(res.data)) {
                return null;
            } else if (!res.data[0]) {
                return new ConfigurationProduct(res.data);
            } else {
                return new ConfigurationProduct(res.data[0]);
            }
        } catch (err) {
            console.error(...new ExxComError(930921, scriptName, err).stamp());
        }
    }

    async getAllConfigurationProducts() {
        try {
            const res = await apiService.get(['configurations/products/find']);

            if (!res.success) {
                throw res;
            } else {
                return res.data.map((p: ConfigurationProduct) => new ConfigurationProduct(p));
            }
        } catch (err) {
            const suppress = ['Unknown Error', 'cannotconnect'];
            if (err && contains(suppress, err.statusText)) {
                return [];
            }
            console.error(...new ExxComError(939198, scriptName, err).stamp());
        }
    }

    async createConfigurationProduct(values: ConfigurationProductData): Promise<ConfigurationProductResponse> {
        try {
            const res: ConfigurationProductResponse = await apiService.post('configurations/products/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            const message = get(err, 'error.message');
            if (message != 'Already exists') {
                console.error(...new ExxComError(336912, scriptName, err).stamp());
            }
            return err as ExxComResponse;
        }
    }

    async createConfiguration(values: ConfigurationDataV2) {
        try {
            const res = await apiService.post('configurations/create', values);
            if (!res.success) {
                throw res;
            }
            return new ConfigurationV2(res.data);
        } catch (err) {
            const message = get(err, 'error.message');
            if (message != 'Already exists') {
                console.error(...new ExxComError(336912, scriptName, err).stamp());
            }
            return err;
        }
    }

    async updateConfiguration(values: ConfigurationDataV2) {
        try {
            const res = await apiService.post('configurations/update', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async deleteConfigurationById(id: string) {
        try {
            const res = await apiService.post('configurations/delete', { id });
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        }
    }

    async updateConfigurationProduct(values: ConfigurationProductData) {
        try {
            const res = await apiService.post('configurations/products/update', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async getAllConfigurationProductLines(type: string) {
        try {
            const res = await apiService.get([
                'configurations/product/lines/find',
                `?filters={
          "type": "${type}"
        }`,
            ]);

            if (!res.success) {
                throw res;
            } else {
                return res.data.map((p: ConfigurationProductLine) => new ConfigurationProductLine(p));
            }
        } catch (err) {
            const suppress = ['Unknown Error', 'cannotconnect'];
            if (err && contains(suppress, err.statusText)) {
                return [];
            }
            console.error(...new ExxComError(932298, scriptName, err).stamp());
        }
    }

    async getConfigurationProductLine(type: string, name: string, isProductComponent?: boolean) {
        try {
            if (name.indexOf('/') == 0) {
                name = name.slice(1);
            }

            const res = await apiService.get([
                'configurations/product/lines/find',
                `?filters={
            "name": "${name}",
            "type": "${type}"
          }`,
                '&limit=1',
            ]);

            if (!res.success) {
                throw res;
            } else if (isEmpty(res.data)) {
                return null;
            } else {
                return new ConfigurationProductLine(res.data[0]);
            }
        } catch (err) {
            console.error(...new ExxComError(931021, scriptName, err).stamp());
        }
    }

    async createConfigurationProductLine(values: ConfigurationProductLineData): Promise<ConfigurationProductLineResponse> {
        try {
            const res: ConfigurationProductLineResponse = await apiService.post('configurations/product/lines/create', values);
            if (!res.success) {
                return res;
            }
            return res;
        } catch (err) {
            const message = get(err, 'error.message');
            if (message != 'Already exists') {
                console.error(...new ExxComError(944912, scriptName, err).stamp());
            }
            return err as ExxComResponse;
        }
    }

    async updateConfigurationProductLine(values: ConfigurationProductLineData) {
        try {
            const res = await apiService.post('configurations/product/lines/update', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async createConnectorType(values: ConfigurationConnectorTypeData) {
        try {
            const res = await apiService.post('configurations/connector/type/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(931022, scriptName, err).stamp());
        }
    }

    async getConnectorTypes() {
        try {
            const res = await apiService.get('configurations/connector/type/find');
            if (!res.success) {
                throw res;
            }
            return res.data;
        } catch (err) {
            console.error(...new ExxComError(931023, scriptName, err).stamp());
        }
    }

    async getBasePrice(config: ConfigurationDataV2, site?: 'exx' | 'spc') {
        try {
            if (config) {
                const configMarkup = config.markupPercent;
                const configProductMap: Map<string, Object> = new Map();
                let totalPrice = 0;

                // Construct product map
                config.products.forEach((product: any) => {
                    configProductMap.set(product._id, product);
                });

                let categories;
                if (site === 'spc' && typeof config.referenceId === 'object' && config.referenceId?.optimizedConfigurations) {
                    categories = config.referenceId.optimizedConfigurations['good']?.selectedSpecs;
                } else if (typeof config === 'object' && config.optimizedConfigurations) {
                    categories = config.optimizedConfigurations['good'].selectedSpecs;
                } else {
                    return null;
                }

                // Calculate pricing based on 'good' reference bomb selected product pricing * quantity
                for (const category of categories) {
                    const categoryIndex = categories.findIndex((spec) => spec.category === category.category);
                    if (categoryIndex === -1) {
                        // Handle case where category does not exist
                        categories[category.category] = [];
                    }

                    for (const product of category.products) {
                        const mappedProduct: any = configProductMap.get(product._id);
                        if (mappedProduct) {
                            totalPrice += mappedProduct.cost * (1 + configMarkup / 100) * product.quantity;
                        }
                    }
                }
                return totalPrice;
            } else return null;
        } catch (err) {
            console.error(...new ExxComError(931024, scriptName, err).stamp());
        }
    }

    async getConfigurationWithBasePrice(site: 'exx' | 'spc', mpn: string) {
        try {
            const res = await this.getConfigurationByUrlComponent(site, mpn, null, null, null, true);
            let basePrice = null;
            if (res && res[0]) {
                basePrice = await this.getBasePrice(res[0], site);
                await Object.assign(res[0], { basePrice });
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(931025, scriptName, err).stamp());
        }
    }

    /**
     * @function isBlacklisted
     * @description Determines if a link or MPN is blacklisted or not
     * @param {string} mpn
     * @returns {boolean} isBlacklisted
     */
    isBlacklisted(mpn: string) {
        let isBlacklisted = false;
        this.configSuffixBlacklist.forEach((suffix) => {
            isBlacklisted = mpn.includes(suffix) || isBlacklisted;
        });
        return isBlacklisted;
    }
}
