<section
    *ngIf="get(subcategoryLinksRow, 'subcategory_links.subcategory_links')"
    [class.gray-bg]="get(subcategoryLinksRow, 'subcategory_links.gray_background')"
>
    <div
        class="d-flex flex-row justify-content-center flex-wrap category-subcategory-links-container"
        *ngFor="let row of get(subcategoryLinksRow, 'subcategory_links.subcategory_links', [])"
    >
        <div class="category-top-title d-flex flex-row justify-content-center pb-2">
            <h2 *ngIf="get(row, 'title_line', '')" class="col-md-7 text-center">
                {{ get(row, 'title_line', '') }}
            </h2>
        </div>
        <div class="subcategory-column-container grid partial-grid container fx-wrap gridless">
            <div
                *ngFor="let subcategory of get(row, 'subcategory_links', [])"
                class="g-col-12 subcategory-column"
                [class.g-col-lg-4]="get(row, 'without_specs', false)"
                [class.g-col-lg-6]="!get(row, 'without_specs', false)"
                [class.has-specs]="!get(row, 'without_specs', false) && !isNarrow"
            >
                <div class="triangle">
                    <div></div>
                </div>
                <a
                    *ngIf="get(subcategory, 'link.routerLink', '')"
                    [routerLink]="get(subcategory, 'link.routerLink', '')"
                    class="category-subcategory-link d-flex flex-row align-items-center pr-2"
                >
                    <img
                        *ngIf="get(subcategory, 'image.href', '')"
                        class="category-subcategory-link-img mx-3"
                        [src]="getWebpImg(get(subcategory, 'image.href', ''))"
                        alt="Subcategory image"
                    />

                    <div class="category-subcategory-spec-container d-flex flex-column justify-content-center">
                        <div>
                            <h4 *ngIf="get(subcategory, 'category_name', '')" class="category-subcategory-img-header bold">
                                {{ get(subcategory, 'category_name', '') }}
                            </h4>
                            <p *ngIf="get(subcategory, 'short_description', '')" class="category-subcategory-description my-1">
                                {{ get(subcategory, 'short_description', '') }}
                            </p>
                        </div>

                        <div *ngIf="!isNarrow">
                            <div *ngFor="let spec of get(subcategory, 'value_bullets', [])">
                                <div class="category-subcategory-spec d-flex flex-row align-items-center mb-base">
                                    <img
                                        class="category-subcategory-spec-img"
                                        [src]="getWebpImg(get(spec, 'icon.href', ''))"
                                        alt="Subcategory specification image"
                                    />
                                    <span class="category-subcategory-spec-label">{{ get(spec, 'bullets_line', '') }}</span>
                                </div>
                            </div>
                        </div>

                        <span *ngIf="get(subcategory, 'link.routerLink', '')" class="link-blue d-flex flex-row align-items-center mb-2">
                            <span>Learn more</span>
                            <mat-icon>chevron_right</mat-icon>
                        </span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(subcategoryLinksRow, 'subcategory_links.split_line', '')" class="m-0 mb-3" />
