import { Inject, Injectable } from '@angular/core';

import { AccountService } from 'lib/services/account/account.service';
import { Address } from 'lib/interfaces/address.interface';
import { ApiService } from 'lib/services/api.service';
import { Cart } from 'lib/services/cart/cart.class';
import { CartService } from 'lib/services/cart/cart.service';
import { CartItem } from 'lib/services/cart/cart.interface';
import { clone, get, isEmpty, isEqual, omit, pick } from 'lodash';
import { countriesByNameLower } from 'lib/lists/countries';
import { EasyPostShipping } from 'lib/services/easypost/easypost-shipping.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { GtmService } from 'lib/services/google/gtm.service';
import { Order } from 'lib/services/order/order.class';
import { OrderLine, OrderStripePayment, DiscountData, DiscountSummary } from 'lib/services/order/order.interface';
import { RouterService } from 'lib/services/router.service';
import { SessionService } from 'lib/services/session.service';
import { StripeService } from 'lib/services/stripe/stripe.service';
import { Subject } from 'rxjs';
import { VoucherifyService } from './voucherify/voucherify.service';

const scriptName = 'checkout.service';

let accountService: AccountService;
let apiService: ApiService;
let cartService: CartService;
let environment: any;
let gtmService: GtmService;
let routerService: RouterService;
let sessionService: SessionService;
let stripeService: StripeService;
let voucherifyService: VoucherifyService;

@Injectable()
export class CheckoutService {
    private _billingAddress: any = {};
    private _guestBillingAddress: any = {};
    private _guestShippingAddress: any = {};
    private _hasError: boolean = false;
    private _isBillingAddressComplete: boolean = false;
    private _isBillingAddressFormMode: boolean = false;
    private _isBillingMethodComplete: boolean = false;
    private _isShippingAddressComplete: boolean = false;
    private _isShippingAddressFormMode: boolean = false;
    private _isShippingMethodComplete: boolean = false;
    private _selectedBillingMethodId: string;
    private _selectedShippingMethodId: string;
    private _shippingAddress: any = {};
    private _shippingMethods: any = [{}, {}, {}, {}, {}, {}];

    easypostShipping: EasyPostShipping = null;
    hideOnSuccessBeforeReload: boolean = false;
    isSubmitted: boolean = false;
    shippingMethodsChanged$: Subject<null> = new Subject<null>();
    shippingAddressChanged$: Subject<null> = new Subject<null>();
    submitError: any = { message: '' };
    validationEnabled: boolean = true;
    defaultShippingInitialized: boolean = false;

    constructor(
        @Inject('environment') e: any,
        ac: AccountService,
        ap: ApiService,
        c: CartService,
        g: GtmService,
        r: RouterService,
        se: SessionService,
        st: StripeService,
        vs: VoucherifyService
    ) {
        try {
            accountService = ac;
            apiService = ap;
            cartService = c;
            environment = e;
            gtmService = g;
            routerService = r;
            sessionService = se;
            stripeService = st;
            voucherifyService = vs;
            this.easypostShipping = new EasyPostShipping({ apiService });
        } catch (err) {
            console.error(...new ExxComError(429883, scriptName, err).stamp());
        }
    }

    get addresses() {
        return get(accountService.account, 'addresses.all') || [];
    }
    get hasError() {
        return this._hasError;
    }
    set hasError(value: boolean) {
        this._hasError = value;
    }

    // SHIPPING ADDRESS

    get guestShippingAddress() {
        return this._guestShippingAddress || {};
    }
    set guestShippingAddress(value: any) {
        this._guestShippingAddress = value || {};
    }
    get shippingAddress() {
        if (!isBrowser()) {
            return {};
        }
        if (!isEmpty(this._shippingAddress)) {
            return this._shippingAddress;
        } else {
            let localId = localStorage.getItem('selectedShippingAddressId');
            if (localId == 'null') {
                localId = null;
            }
            const address = this.addresses && this.addresses.find((address: any) => address._id == localId);
            return address || {};
        }
    }
    set shippingAddress(value: any) {
        try {
            if (!isBrowser() || !value) {
                return;
            }
            const omitFields = ['_id', 'createdAt', 'update', 'updatedAt'];
            const isSame = isEqual(omit(this.shippingAddress, omitFields), omit(value, omitFields));
            if (isSame) {
                return;
            }
            localStorage.setItem('selectedShippingAddressId', (value && value._id) || null);
            this._shippingAddress = value;
            this.shippingAddressChanged$.next(void 0);
        } catch (err) {
            console.error(...new ExxComError(520918, scriptName, err).stamp());
        }
    }
    get isShippingAddressFormMode() {
        return this._isShippingAddressFormMode;
    }
    set isShippingAddressFormMode(value: boolean) {
        this._isShippingAddressFormMode = value;
    }

    // SHIPPING METHODS

    get shippingMethods() {
        return this._shippingMethods;
    }
    set shippingMethods(value: any) {
        this._shippingMethods = value;
        this.shippingMethodsChanged$.next(void 0);
    }
    get selectedShippingMethodId() {
        if (!isBrowser()) {
            return '';
        }
        const local = localStorage.getItem('selectedShippingMethodId');
        return (local == 'null' ? null : local) || this._selectedShippingMethodId;
    }
    set selectedShippingMethodId(value: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            if (value == this._selectedShippingMethodId) {
                return;
            }
            localStorage.setItem('selectedShippingMethodId', value || null);
            this._selectedShippingMethodId = value == 'null' ? null : value;
            this.shippingMethodsChanged$.next(void 0);
        } catch (err) {
            console.error(...new ExxComError(510923, scriptName, err).stamp());
        }
    }

    async setSelectedShippingMethodId(value: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            if (value == this._selectedShippingMethodId) {
                return;
            }
            localStorage.setItem('selectedShippingMethodId', value || null);
            this._selectedShippingMethodId = value == 'null' ? null : value;
            this.shippingMethodsChanged$.next(void 0);
        } catch (err) {
            console.error(...new ExxComError(510923, scriptName, err).stamp());
        }
    }

    get selectedShippingMethod() {
        return this.shippingMethods.find((method: any) => method.excId == this.selectedShippingMethodId);
    }

    // SHIPPING COMPLETION

    get isShippingAddressComplete() {
        return this._isShippingAddressComplete;
    }
    set isShippingAddressComplete(value: boolean) {
        this._isShippingAddressComplete = value;
    }
    get isShippingMethodComplete() {
        return this._isShippingMethodComplete;
    }
    set isShippingMethodComplete(value: boolean) {
        this._isShippingMethodComplete = value;
    }

    async setIsShippingMethodComplete(value: boolean) {
        this._isShippingMethodComplete = value;
    }

    get isShippingComplete() {
        return this.isShippingAddressComplete && this.isShippingMethodComplete;
    }

    // BILLING ADDRESS

    get guestBillingAddress() {
        return this._guestBillingAddress || {};
    }
    set guestBillingAddress(value: any) {
        this._guestBillingAddress = value || {};
    }
    get billingAddress() {
        if (!isBrowser()) {
            return '';
        }
        if (!isEmpty(this._billingAddress)) {
            return this._billingAddress;
        } else {
            let localId = localStorage.getItem('selectedBillingAddressId');
            if (localId == 'null') {
                localId = null;
            }
            const address = this.addresses && this.addresses.find((address: any) => address._id == localId);
            return address || {};
        }
    }
    set billingAddress(value: any) {
        try {
            if (!isBrowser() || isEqual(this.billingAddress, value)) {
                return;
            }
            localStorage.setItem('selectedBillingAddressId', (value && value._id) || null);
            this._billingAddress = value;
        } catch (err) {
            console.error(...new ExxComError(410928, scriptName, err).stamp());
        }
    }
    get isBillingddressFormMode() {
        return this._isBillingAddressFormMode;
    }
    set isBillingAddressFormMode(value: boolean) {
        this._isBillingAddressFormMode = value;
    }
    removeBillingAddress() {
        try {
            if (!isBrowser()) {
                return;
            }
            localStorage.removeItem('selectedBillingAddressId');
            this._billingAddress = {};
        } catch (err) {
            console.error(...new ExxComError(409288, scriptName, err).stamp());
        }
    }

    // BILLING METHODS

    get selectedBillingMethodId() {
        if (!isBrowser()) {
            return '';
        }
        const local = localStorage.getItem('selectedBillingMethodId');
        return (local == 'null' ? null : local) || this._selectedBillingMethodId;
    }
    set selectedBillingMethodId(value: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            localStorage.setItem('selectedBillingMethodId', value || null);
            this._selectedBillingMethodId = value == 'null' ? null : value;
        } catch (err) {
            console.error(...new ExxComError(610938, scriptName, err).stamp());
        }
    }
    get selectedBillingMethod() {
        const cards = get(accountService.account, 'cards', []);
        const card = cards.find((card: any) => card.id == this.selectedBillingMethodId);
        if (card) {
            card.backgroundImage = `url('../${accountService.getCardIconPath(card.brand)}')`;
        }
        return card;
    }

    // BILLING COMPLETION

    get isBillingAddressComplete() {
        return this._isBillingAddressComplete;
    }
    set isBillingAddressComplete(value: boolean) {
        this._isBillingAddressComplete = value;
    }
    get isBillingMethodComplete() {
        return this._isBillingMethodComplete;
    }
    set isBillingMethodComplete(value: boolean) {
        this._isBillingMethodComplete = value;
    }
    get isBillingComplete() {
        return this.isBillingAddressComplete && !this.isBillingAddressFormMode && this.isBillingMethodComplete;
    }

    // HELPERS
    async submit() {
        try {
            if (this.isSubmitted) {
                return;
            }
            this.isSubmitted = true;
            this.submitError.message = '';

            const isGuest = sessionService.isGuestSession();

            const parseOrder = () => {
                const addresses: any = {
                    billing: clone(isGuest ? this.guestBillingAddress : this.billingAddress) as Address,
                    shipping: clone(isGuest ? this.guestShippingAddress : this.shippingAddress) as Address,
                };
                if (isEmpty(addresses.billing)) {
                    throw new Error('Billing address not selected');
                }
                if (isEmpty(addresses.shipping)) {
                    throw new Error('Shipping address not selected');
                }
                const billingCountry = countriesByNameLower[addresses.billing.country.toLowerCase()];
                addresses.billing.country = billingCountry ? billingCountry.code : addresses.billing.country;
                const shippingCountry = countriesByNameLower[addresses.shipping.country.toLowerCase()];
                addresses.shipping.country = shippingCountry ? shippingCountry.code : addresses.shipping.country;

                const card: any = pick(get(this, 'selectedBillingMethod', {}), ['brand', 'expMonth', 'expYear', 'id', 'last4', 'name']);
                if (isEmpty(card)) {
                    throw new Error('Billing method not selected');
                }

                const cart: any = pick(get(cartService, 'cart', {}), ['_id', 'items', 'summary', 'stripePayment']) as Cart;
                if (isEmpty(cart)) {
                    throw new Error('Cart not available');
                }
                const customer: any = pick(get(accountService, 'account', {}), ['_id', 'first', 'last', 'nsid', 'email', 'stripeId']);
                if (isEmpty(customer)) {
                    throw new Error('Customer data not available');
                }

                const shippingMethod: any = pick(get(this, 'selectedShippingMethod', {}), [
                    'excCarrier',
                    'excDeliveryDate',
                    'excName',
                    'excNsid',
                    'est_ship_date',
                ]);
                if (isEmpty(shippingMethod)) {
                    throw new Error('Shipping method not available');
                }
                shippingMethod.deliveryDate = shippingMethod.delivery_date;
                delete shippingMethod.delivery_date;

                const discountData: DiscountData = voucherifyService.getDiscountData();
                const discountSummary: DiscountSummary = voucherifyService.getDiscountSummary();

                const unmodifiedLines: OrderLine[] = cart.items.map((item: CartItem) => {
                    const line: OrderLine = {
                        item: item.product.name,
                        itemNsid: item.product.nsid,
                        product: item.product._id,
                        quantity: item.quantity,
                        rate: item.price,
                    };
                    return line;
                });

                let modifiedLines = null;

                // Modify lines based on discounts
                // Lines are synced to NS
                if (discountData.modifiedItems) {
                    modifiedLines = discountData.modifiedItems.map((item: CartItem) => {
                        const line: OrderLine = {
                            item: item.product.name,
                            itemNsid: item.product.nsid,
                            product: item.product._id,
                            quantity: item.quantity,
                            rate: item.price,
                        };
                        return line;
                    });
                }

                const order = {
                    salesRep: '60: WEB',
                    shipEarly: false,
                    signatureRequired: false,
                    status: 'Received',
                    type: 'webstore',
                    webstore: environment.siteAbbr,

                    addresses: {
                        billing: addresses.billing,
                        shipping: addresses.shipping,
                    },

                    amountShipping: cart.summary.shipping,
                    amountSubtotal: cart.summary.subtotal,
                    amountTax: cart.summary.tax,
                    amountTotal: cart.summary.total,

                    cart: cart._id,
                    discountData: discountData,
                    discountSummary: discountSummary,

                    customer: customer._id,
                    customerName: `${customer.first} ${customer.last}`,
                    customerNsid: customer.nsid,
                    email: customer.email,

                    shipDate: shippingMethod.est_ship_date,

                    lines: unmodifiedLines,
                    modifiedLines: modifiedLines,

                    shippingMethod: {
                        carrier: shippingMethod.excCarrier,
                        deliveryDate: shippingMethod.excDeliveryDate,
                        name: shippingMethod.excName,
                        nsid: shippingMethod.excNsid,
                    },

                    stripePayment: {
                        clientSecret: cart.stripePayment.clientSecret,
                        intentId: cart.stripePayment.intentId,
                        card: {
                            brand: card.brand,
                            expMonth: card.expMonth,
                            expYear: card.expYear,
                            id: card.id,
                            last4: card.last4,
                            name: card.name,
                        },
                    } as OrderStripePayment,
                };

                return order as Order;
            };

            // Parse
            let order = parseOrder();
            const firstIntent = order.stripePayment.intentId;
            // Confirm payment
            const cart = get(cartService, 'cart', {}) as Cart;
            if (isEmpty(cart)) {
                throw new Error('Cart not available');
            }
            const res = await stripeService.confirmPaymentIntent({
                order,
                cart: cartService.cart,
            });
            if (!res.success) {
                throw res;
            }

            // Re-parse

            const newOrder = parseOrder(); // Used to check if payment intent had to be canceled and recreated during confirmation
            const secondIntent = newOrder.stripePayment.intentId;

            const updatedCart = newOrder;
            updatedCart._id = cart._id;
            await cartService.updateCart(updatedCart);
            // If the intents are not the same, we created a new intent because the amounts were different from cart and order
            if (firstIntent != secondIntent) {
                const res = await stripeService.confirmPaymentIntent({
                    order: newOrder,
                    cart: cartService.cart,
                });
                order = newOrder;

                if (!res.success) {
                    throw res;
                }
            }

            // Create
            const orderRes = await apiService.post('orders/create', order);
            if (!orderRes.success) {
                throw orderRes;
            }

            if (!orderRes.data.refNumber) {
                const error = {
                    order,
                    orderRes,
                    message: 'Order could not be created, try ordering again or call us 408-914-8196',
                };
                throw error;
            }

            // Finalize

            await this.finalize(orderRes.data);
        } catch (err) {
            if (err.name == 'HttpErrorResponse') {
                this.submitError.message = 'Network error';
            } else {
                this.submitError.message = get(err, 'error.message') || err.message;
            }
            await new Promise(() => {
                setTimeout(() => (this.isSubmitted = false), 1000);
            });
            if (get(err, 'error.code') == 'card_declined') {
                return;
            }
            console.error(...new ExxComError(410488, scriptName, err).stamp());
        }
    }

    async finalize(order: Order): Promise<void> {
        try {
            gtmService.trackTransaction(order);

            /**
             * Hide the page before reloading
             * - Improves UX by hiding the full page while the cart is being reset.
             * Otherwise, only the cart summary goes blank, which could be a little
             * concerning for a customer becuase the dollars disappear.
             */
            this.hideOnSuccessBeforeReload = true;

            /**
             * Redeems valid coupons in cart
             */
            await voucherifyService.redeemCouponStackable();

            /**
             * Update finished cart
             * - Setting status to "Finished" ensures finished carts are not included
             * later when searching for carts, which makes the find operation more
             * efficient, may support cart analytics in the future, and enables
             * removing old unused carts from the database without deleting ones that
             * were used.
             * - Adds the order refNumber to the cart in the database to enable
             * searching for the order by refNumber when the confirmation page
             * component is loaded (and possibly searching by refNumber elsewhere in
             * the future).
             */
            await cartService.finalize(order.refNumber);

            /**
             * Reset the checkout service data
             * - Resets the state of the various checkout service properties.
             */
            this.reset();

            /**
             * Retrieve accoout
             * - Retrieves updated customer data, including the new order.
             */
            await accountService.account.get();

            /**
             * Redirect to confirmation page
             * - Redirects to the confirmation page.
             */
            await routerService.router.navigateByUrl(`/checkout/confirmation?ref=${order.refNumber}`);
        } catch (err) {
            console.error(...new ExxComError(420938, scriptName, err).stamp());
        }
    }

    reset(): void {
        try {
            this.billingAddress = null;
            this.easypostShipping = new EasyPostShipping({ apiService });
            this.hasError = false;
            this.guestBillingAddress = null;
            this.guestShippingAddress = null;
            this.isBillingAddressComplete = false;
            this.isBillingAddressFormMode = false;
            this.isBillingMethodComplete = false;
            this.isShippingAddressComplete = false;
            this.isShippingAddressFormMode = false;
            this.isShippingMethodComplete = false;
            this.shippingAddress = null;
            this.shippingMethods = [{}, {}, {}, {}, {}, {}];
            this.isSubmitted = false;
        } catch (err) {
            console.error(...new ExxComError(520938, scriptName, err).stamp());
        }
    }
}
