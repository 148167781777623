<div id="cms-news-events-post">
    <div class="cms-news-banner">
        <div class="fx-row fx-row-container fx-row-v-center fx-row-h-left py-4 px-3">
            <a href="/News-Events" class="fx-row">
                <div class="fx-row fx-row-v-center mr-1">
                    <mat-icon svgIcon="message-square"></mat-icon>
                </div>
                <h3>News and Events</h3>
            </a>
        </div>
    </div>
    <div class="fx-row fx-row-container py-5">
        <div class="blog-post-buttons col-sm-1" *ngIf="!isNarrow">
            <a class="share-2 logo-button" (click)="copyLink($event)" target="_blank">
                <mat-icon svgIcon="share-2"></mat-icon>
            </a>
            <a
                class="twitter logo-button mt-1"
                href="https://twitter.com/intent/tweet?text=https://www.exxactcorp.com{{ entry?.url }}"
                target="_blank"
            >
                <mat-icon svgIcon="x-logo"></mat-icon>
            </a>
            <a
                class="facebook logo-button mt-1"
                href="https://www.facebook.com/sharer/sharer.php?u=https://www.exxactcorp.com{{ entry?.url }}"
                target="_blank"
            >
                <mat-icon svgIcon="facebook"></mat-icon>
            </a>
        </div>
        <div class="col-sm-9">
            <div class="date-location">{{ date }}</div>
            <h4 class="mt-1 old">Press Release</h4>
            <h1>{{ entry?.press_release_title }}</h1>
            <div *ngIf="isNarrow" class="fx-row mb-3">
                <a class="share-2 mr-1 logo-button" (click)="copyLink($event)" target="_blank">
                    <mat-icon svgIcon="share-2"></mat-icon>
                </a>
                <a
                    class="twitter mr-1 logo-button"
                    href="https://twitter.com/intent/tweet?text=https://www.exxactcorp.com{{ entry?.url }}"
                    target="_blank"
                >
                    <mat-icon svgIcon="twitter"></mat-icon>
                </a>
                <a
                    class="facebook logo-button"
                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.exxactcorp.com{{ entry?.url }}"
                    target="_blank"
                >
                    <mat-icon svgIcon="facebook"></mat-icon>
                </a>
            </div>
            <div class="date-location">{{ location }}</div>
            <div *ngIf="entry?.paragraph" class="post-article" [innerHTML]="entry.paragraph | safe: 'html'"></div>
        </div>
    </div>
</div>
