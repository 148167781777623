<section
    *ngIf="get(featureSolutionsRow, 'feature_solutions', '')"
    [class.gray-bg]="get(featureSolutionsRow, 'feature_solutions.gray_background', '')"
>
    <div class="d-flex flex-wrap flex-row container-gridless feature-solutions">
        <div
            *ngIf="get(featureSolutionsRow, 'feature_solutions.feature_solutions_title', '')"
            class="category-top-title d-flex flex-row justify-content-center"
        >
            <h2 class="col-md-9 text-center m-0">
                {{ get(featureSolutionsRow, 'feature_solutions.feature_solutions_title', '') }}
            </h2>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-center w-100">
            <div
                *ngFor="let solution of get(featureSolutionsRow, 'feature_solutions.feature_solutions[0].feature_solutions', []); index as i"
                class="col-md-4 solution"
            >
                <div class="d-flex flex-column card-1">
                    <div
                        id="cms-partial-category-feature-solutions-img"
                        class="text-center"
                        [class.hover-effect]="get(solution, 'solution_page_link.routerLink', '')"
                        [ngStyle]="{
                            'background-image': 'url(' + getBackgroundImage(i) + ')',
                            'background-repeat': 'no-repeat',
                            'background-size': 'contain'
                        }"
                    >
                        <a
                            [href]="
                                get(solution, 'solution_page_link.routerLink', '') !== '' ? get(solution, 'solution_page_link.routerLink', '') : []
                            "
                        >
                            <img
                                *ngIf="get(solution, 'solution_image.href', '')"
                                [routerLink]="
                                    get(solution, 'solution_page_link.routerLink', '') !== ''
                                        ? get(solution, 'solution_page_link.routerLink', '')
                                        : []
                                "
                                [src]="getWebpImg(get(solution, 'solution_image.href', ''))"
                                alt="Solution image"
                            />
                        </a>
                    </div>

                    <a
                        class="top-card px-3 text-center pt-3"
                        [class.hover-effect]="get(solution, 'solution_page_link.routerLink', '')"
                        [routerLink]="
                            get(solution, 'solution_page_link.routerLink', '') !== '' ? get(solution, 'solution_page_link.routerLink', '') : []
                        "
                    >
                        <a *ngIf="get(solution, 'solution_page_link.routerLink', '')" class="title text-center">
                            <h4 class="solution-title text-center m-0">
                                <ng-container *ngIf="solution.solution_title; let splitTitle">
                                    <span>{{ splitWord(splitTitle).firstPart }}</span>
                                    <span class="last-word">
                                        <span [class.last-word-text]="get(solution, 'solution_page_link.routerLink', '')">{{
                                            splitWord(splitTitle).lastPart
                                        }}</span>
                                        <i-feather *ngIf="get(solution, 'solution_page_link.routerLink', '')" name="chevron-right"></i-feather>
                                    </span>
                                </ng-container>
                            </h4>
                        </a>
                        <h4 *ngIf="!get(solution, 'solution_page_link.routerLink', '')" class="solution-title text-center m-0">
                            <ng-container *ngIf="solution.solution_title; let splitTitle">
                                <span>{{ splitWord(splitTitle).firstPart }}</span>
                                <span class="last-word">
                                    <span [class.last-word-text]="get(solution, 'solution_page_link.routerLink', '')">{{
                                        splitWord(splitTitle).lastPart
                                    }}</span>
                                    <i-feather *ngIf="get(solution, 'solution_page_link.routerLink', '')" name="chevron-right"></i-feather>
                                </span>
                            </ng-container>
                        </h4>
                        <a [href]="get(solution, 'solution_page_link.routerLink', '')">
                            <div
                                *ngIf="get(solution, 'part_number', '')"
                                class="part-number text-center px-1 pt-base pb-1"
                                [routerLink]="get(solution, 'solution_page_link.routerLink', '')"
                            >
                                {{ get(solution, 'part_number', '') }}
                            </div>
                        </a>
                        <hr class="divider mb-0 mt-1" />

                        <div class="d-flex flex-column align-items-center mt-3">
                            <div *ngIf="solution.sierraActive && get(solution, 'price', '')">
                                <div class="text-center font-color start-text">Starting at</div>
                                <h4 class="mt-base pb-2 text-center price">{{ get(solution, 'price', '') | currency }}</h4>
                            </div>
                            <a
                                *ngIf="get(solution, 'button_text_select')"
                                class=""
                                [routerLink]="get(solution, 'spec_link.routerLink', '')"
                                [queryParams]="get(solution, 'spec_link.queryParams', {})"
                            >
                                {{ get(solution, 'button_text_select', '') }}
                            </a>
                        </div>
                    </a>

                    <div class="px-3 pb-3 lower-half-card d-flex flex-column fx-col justify-content-between">
                        <div>
                            <div *ngFor="let props of get(solution, 'system_value_props', '')" class="d-flex flex-row lower-half-card-container">
                                <img [src]="getWebpImg(get(props, 'icon.href', ''))" alt="Solution value property image" />
                                <span class="value-line">{{ props.value_prop_line }}</span>
                            </div>

                            <h5 *ngIf="!isEmpty(get(solution, 'specs', ''))" class="mb-1" [class.pt-2]="get(solution, 'system_value_props').length">
                                Highlights
                            </h5>

                            <div *ngFor="let spec of get(solution, 'specs', [])" class="d-flex flex-row specs lower-half-card-container">
                                <span class="spec-labels">{{ get(spec, 'spec_label', '') }}</span>
                                <span class="value-line">{{ get(spec, 'spec_line', '') }}</span>
                            </div>
                        </div>
                        <div (click)="onButtonClick($event)">
                            <ng-container *ngIf="solution?.configExists && !configuratorService?.isBlacklisted(solution?.configuratorUrl)">
                                <!-- for new -->
                                <a
                                    *ngIf="solution.sierraActive"
                                    [href]="'https://configurator.exxactcorp.com' + '/configure/' + get(solution, 'part_number', '')"
                                >
                                    <button-component
                                        [buttonOptions]="{
                                            text: 'Configure',
                                            type: 'primary',
                                            iconPosition: 'right',
                                            iconName: 'tool',
                                            class: 'w-100 mt-2'
                                        }"
                                    ></button-component>
                                </a>

                                <!-- for old -->
                                <a [href]="'../../' + get(solution, 'part_number', '') + '/configurator/'">
                                    <button-component
                                        *ngIf="!solution.sierraActive"
                                        [buttonOptions]="{
                                            text: 'Configure',
                                            type: 'primary',
                                            iconPosition: 'right',
                                            internalLink: '../../' + get(solution, 'part_number', '') + '/configurator/',
                                            iconName: 'tool',
                                            class: 'w-100 mt-2'
                                        }"
                                    ></button-component>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="get(solution, 'marketo_form_selection.form_selection', '') !== 'no_form'">
                                <button-component
                                    data-cy="marketo-form"
                                    *ngIf="get(solution, 'marketo_form_selection.form_selection', '') == 'default_form'"
                                    (click)="
                                        bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                                            title: dataTitle,
                                            url: dataUrl,
                                            productInfo: {
                                                mpn: get(
                                                    featureSolutionsRow,
                                                    'feature_solutions.feature_solutions[0].feature_solutions[' + i + '].part_number',
                                                    ''
                                                ),
                                                solutionTitle: get(
                                                    featureSolutionsRow,
                                                    'feature_solutions.feature_solutions[0].feature_solutions[' + i + '].solution_title',
                                                    ''
                                                )
                                            }
                                        })
                                    "
                                    [buttonOptions]="{
                                        text:
                                            solution?.button_text == undefined || solution?.button_text == '' ? 'Get a Quote' : solution?.button_text,
                                        type: 'secondary',
                                        iconPosition: 'right',
                                        iconName: 'chevron-right',
                                        class: 'w-100 mt-1'
                                    }"
                                ></button-component>
                                <button-component
                                    data-cy="marketo-form"
                                    *ngIf="get(solution, 'marketo_form_selection.form_selection', '') == 'other_form'"
                                    (click)="
                                        bootstrapMarketoService.openBootstrapMarketoDialog(
                                            get(solution, 'marketo_form_selection.marketo_forms.id', ''),
                                            'edit',
                                            {
                                                title: dataTitle,
                                                url: dataUrl
                                            }
                                        )
                                    "
                                    [buttonOptions]="{
                                        text:
                                            solution?.button_text == undefined || solution?.button_text == '' ? 'Get a Quote' : solution?.button_text,
                                        type: 'secondary',
                                        iconPosition: 'right',
                                        iconName: 'chevron-right',
                                        class: 'w-100 mt-1'
                                    }"
                                ></button-component>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(featureSolutionsRow, 'feature_solutions.split_line', '')" class="m-0 mb-3" />
