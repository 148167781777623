<div id="cms-contact-us">
    <div class="contact-us-left">
        <h5>{{ get(entry, 'label', '') }}</h5>
        <h1>{{ get(entry, 'header', '') }}</h1>
        <div class="copy-lg">{{ get(entry, 'copy', '') }}</div>
        <div class="form-card d-flex justify-content-center">
            <lib-marketo-form [formId]="formId" [interest]="interest"></lib-marketo-form>
        </div>
    </div>

    <div class="contact-us-right fx-row fx-row-h-center">
        <div class="contact-us-right-content">
            <div *ngFor="let card of get(entry, 'cards', '')" class="card-section">
                <div class="title bold mb-1">{{ get(card, 'title', '') }}</div>
                <p>{{ get(card, 'copy', '') }}</p>
                <button-component
                    *ngIf="get(card, 'link', false) && !get(card, 'marketo_form.id', false)"
                    class="category-product-quick-view"
                    data-cy="exx-open-product-quick-view-button-desktop"
                    [buttonOptions]="{
                        text: get(card, 'link.title', ''),
                        externalLink: get(card, 'link.href', ''),
                        type: 'secondary',
                        iconPosition: 'right',
                        iconName: 'chevron-right'
                    }"
                ></button-component>
                <button
                    *ngIf="get(card, 'marketo_form.id', false)"
                    class="button-tertiary mb-5"
                    (click)="bootstrapMarketoService.openBootstrapMarketoDialog(get(card, 'marketo_form.id', ''), null, {})"
                >
                    {{ get(card, 'link.title', '') }}
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <button-component
                    *ngIf="get(card, 'marketo_form.id', false)"
                    class="mb-5"
                    (click)="bootstrapMarketoService.openBootstrapMarketoDialog(get(card, 'marketo_form.id', ''), null, {})"
                    [buttonOptions]="{
                        text: get(card, 'link.title', ''),
                        type: 'secondary',
                        iconPosition: 'right',
                        iconName: 'chevron-right'
                    }"
                ></button-component>
            </div>

            <div class="contact-info">
                <div *ngFor="let contact of get(entry, 'contact', '')" class="contact-section fx-row">
                    <img class="cms-contact-icon mr-3" src="{{ get(contact, 'icon.href', '') }}" alt="contact-icon" />
                    <div class="cms-contact-text-section fx-row fx-row-v-center">
                        <div [innerHTML]="get(contact, 'copy', '')"></div>
                        <a
                            *ngIf="get(contact, 'icon.title', '') == 'partner'"
                            [href]="get(contact, 'link.href', '')"
                            class="link-blue fx-row fx-row-v-center"
                        >
                            {{ get(contact, 'link.title', '') }}
                            <mat-icon>chevron_right</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
