import { SimpleChange, SimpleChanges } from '@angular/core';

import { add } from 'lib/tools';
import { BehaviorSubject } from 'rxjs';
import { CartItem, CartResponse, CartShippingAddress, CartStripePayment } from 'lib/services/cart/cart.interface';
import { CartSummary } from 'lib/services/cart/cart-summary.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isEmpty, isEqual } from 'lodash';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';

const scriptName = 'cart.class';

let environment: any;

export class Cart {
    // Schema

    _id: string = null;
    email: string = null;
    items: CartItem[] = [];
    refNumber: string = null;
    shippingAddress: CartShippingAddress = {};
    status: string = null;
    stripePayment: CartStripePayment = { amount: null, clientSecret: null, intentId: null };
    discountData: any;
    discountSummary: any;
    summary: CartSummary = new CartSummary();
    webstore: string = null;

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);
    isEmpty: boolean = true;
    totalItems: number = 0;

    constructor({ dependencies }: { dependencies: { environment: any } }) {
        try {
            environment = dependencies.environment;
        } catch (err) {
            console.error(...new ExxComError(789913, scriptName, err).stamp());
        }
    }

    // Initialize

    init(res: CartResponse): void {
        try {
            const d = res.data;
            const s = d.summary;
            const changes: SimpleChanges = {};
            const isFirstChange = !this._id;
            this._id = d._id;
            if (this.email != d.email) {
                changes.email = new SimpleChange(this.email, d.email, isFirstChange);
                this.email = d.email || null;
            }
            if (!isEqual(this.stripePayment, d.stripePayment)) {
                changes.stripePayment = new SimpleChange(this.stripePayment, d.stripePayment, isFirstChange);
                this.stripePayment = d.stripePayment;
            }
            if (!isEqual(this.shippingAddress, d.shippingAddress)) {
                changes.shippingAddress = new SimpleChange(this.shippingAddress, d.shippingAddress, isFirstChange);
                this.shippingAddress = d.shippingAddress;
            }
            const items = ((d.items || []) as CartItem[])
                .sort((a: CartItem, b: CartItem) => b.line - a.line)
                .map((item: CartItem) => {
                    item.product = new WebstoreProduct(item.product, { environment });
                    return item;
                });
            if (!isEqual(this.items, items)) {
                changes.items = new SimpleChange(this.items, items, isFirstChange);
                this.items = items;
            }
            const totalItems = d.items.reduce((sum: number, item: any) => add(sum, item.quantity), 0);
            if (this.totalItems != totalItems) {
                changes.totalItems = new SimpleChange(this.totalItems, totalItems, isFirstChange);
                this.totalItems = totalItems;
            }
            this.isEmpty = isEmpty(this.items);
            if (this.status != d.status) {
                changes.status = new SimpleChange(this.status, status, isFirstChange);
                this.status = d.status;
            }
            const summary = new CartSummary(s.subtotal, s.shipping, s.tax, s.total);
            if (!isEqual(this.summary, summary)) {
                changes.summary = new SimpleChange(this.summary, summary, isFirstChange);
                this.summary = summary;
            }

            const discountData = d.discountData;
            if (!isEqual(this.discountData, discountData)) {
                changes.discountData = new SimpleChange(this.discountData, discountData, isFirstChange);
                this.discountData = discountData;
            }

            const discountSummary = d.discountSummary;
            if (!isEqual(this.discountSummary, discountSummary)) {
                changes.discountSummary = new SimpleChange(this.discountSummary, discountSummary, isFirstChange);
                this.discountSummary = discountSummary;
            }

            !isEmpty(changes) && this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(499288, scriptName, err).stamp());
        }
    }

    hasItem(productId: string) {
        try {
            return !!this.items.find((item: CartItem) => item.product._id == productId);
        } catch (err) {
            console.error(...new ExxComError(499288, scriptName, err).stamp());
        }
    }
}
