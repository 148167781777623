<section
    id="product-listing"
    [class.not-solution-product-container]="!isSolutionProduct"
    [class.is-quick-view]="isQuickView"
    [class.not-solution-product-container-has-swiper]="!isSolutionProduct && product?.imageUrls?.length > 1"
>
    <div class="responsive-container container">
        <div class="fx-row fx-row-h-center product-listing-image" [class.solutions]="isSolutionProduct" [class.products]="!isSolutionProduct">
            <div
                class="image-container text-center d-flex flex-row align-items-center"
                [class.single-product-swiper]="product?.imageUrls?.length === 1"
            >
                <img *ngIf="!isBrowser && product?.imageUrl" [src]="getWebpImg(product?.imageUrl)" [alt]="product?.name" />

                <div class="d-flex flex-column container-padless box-shadow-parent">
                    <div id="box-shadow" [ngClass]="product?.imageUrls?.length === 1 ? 'single-product' : 'multiple-product'"></div>
                    <mod-swiper
                        (onNumberChange)="setImageIndex($event)"
                        *ngIf="productImgSlides?.length > 0 && isBrowser"
                        class="bottom-swiper-nav container p-0"
                        [slidesPerView]="1"
                        [loop]="false"
                        [containerClass]="uniqueContainerClass"
                        [allowPrevArrow]="false"
                        [allowNextArrow]="false"
                        [productImages]="product?.imageUrls"
                        [isSolution]="isSolutionProduct"
                        [activeIndex]="amount"
                    >
                        <div class="fx-row fx-row-h-center fx-row-v-center swiper-slide" *ngFor="let slide of productImgSlides">
                            <img [src]="getWebpImg(slide?.imageUrl)" [alt]="slide?.heading" (click)="!isNarrow ? openModal(templateModal) : []" />
                        </div>
                    </mod-swiper>
                    <app-product-page-image-tile
                        *ngIf="product?.imageUrls?.length > 1"
                        [(amount)]="amount"
                        [slidesPerView]="4"
                        [productImages]="product?.imageUrls"
                        [(translateAmount)]="translateAmount"
                        [widthPerSlideImage]="66"
                    >
                    </app-product-page-image-tile>
                    <div
                        *ngIf="isQuickView"
                        [ngClass]="product?.imageUrls?.length === 1 ? 'd-flex justify-content-center' : 'd-flex justify-content-center'"
                    >
                        <a
                            (click)="bsModalRef.hide()"
                            [routerLink]="product?.urlComponent"
                            data-cy="exx-view-full-details-quick-view-link"
                            class="d-flex align-items-center mt-3"
                            id="see-more"
                            >See Full Information <i-feather name="chevron-right"></i-feather
                        ></a>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="text-container d-flex flex-column justify-content-sm-between"
            [class.extra-height]="product?.imageUrls?.length > 1 && !isQuickView && isSolutionProduct"
        >
            <div id="title-and-mpn" [class.swiper-runway]="!isSolutionProduct && product?.imageUrls?.length > 1">
                <span>MPN: {{ product?.mpn }}</span>
                <h1 id="product-listing-name">{{ product?.description }}</h1>
                <div id="product-listing-social-media" class="fx-row fx-row-h-left mt-2" *ngIf="!isQuickView">
                    <a
                        id="facebook-icon"
                        href="https://www.facebook.com/sharer/sharer.php?u=https://www.exxactcorp.com{{ product?.urlComponent }}"
                        target="_blank"
                    >
                        <mat-icon svgIcon="facebook"></mat-icon>
                    </a>
                    <a
                        id="twitter-icon"
                        href="https://twitter.com/intent/tweet?text=https://www.exxactcorp.com{{ product?.urlComponent }}"
                        target="_blank"
                    >
                        <mat-icon svgIcon="x-logo" width="20"></mat-icon>
                    </a>
                    <a
                        id="linkedin-icon"
                        href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.exxactcorp.com{{ product?.urlComponent }}"
                        target="_blank"
                    >
                        <mat-icon svgIcon="linkedin"></mat-icon>
                    </a>
                    <a id="share-icon" (click)="copyLink($event)" target="_blank">
                        <mat-icon svgIcon="share-2"></mat-icon>
                    </a>
                </div>
            </div>
            <div id="product-listing-main" class="fx-row fx-wrap px-0">
                <hr class="title-highlights-separator" />
                <div id="product-listing-details" class="w-100">
                    <div class="fx-col" *ngIf="product?.freeShipping">
                        <div class="fx-row fx-row-h-space-between fx-row-v-center">
                            <div id="free-shipping">Free shipping</div>
                        </div>
                        <div class="pt-2">
                            <a
                                [href]="configuratorUrl"
                                class="w-100 configurator-customize-button-spacing m-0"
                                *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product?.mpn)"
                            >
                                <button-component
                                    *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product?.mpn)"
                                    [buttonOptions]="{
                                        text: 'Customize',
                                        type: 'primary',
                                        internalLink: configuratorUrl,
                                        iconPosition: 'right',
                                        iconName: 'tool'
                                    }"
                                ></button-component>
                            </a>
                        </div>
                    </div>

                    <div *ngIf="product?.highlights && !isQuickView">
                        <div id="product-listing-highlights" [class.mb-2x2]="!isSolutionProduct">
                            <span class="highlights">Highlights</span>
                            <div id="highlights">
                                <div *ngFor="let tr of mutilatedHighlights" class="d-flex flex-row highlight-row">
                                    <div *ngFor="let td of tr; let i = index" class="w-50 d-flex align-items-center" [class.pr-3]="i == 0">
                                        <img
                                            class="checkmark"
                                            *ngIf="i == 0"
                                            src="https://resources.exxactcorp.com/resources/exx/assets/icons/value-prop.svg"
                                        />
                                        <div [class.checkmark-spacing]="i == 0">{{ td }}{{ i == 0 && tr.length == 2 ? ':' : '' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="button-separator no-price-separation" *ngIf="!startingPrice && isSolutionProduct" />
                        <div id="starting-price" *ngIf="startingPrice && configuratorExists">
                            <span class="price-text">
                                Starting Price: <span class="price-number">{{ startingPrice }}</span>
                            </span>
                        </div>
                    </div>

                    <div *ngIf="product?.highlights && isQuickView" id="product-listing-highlights">
                        <span class="highlights">Highlights</span>
                        <div [innerHTML]="product?.highlights | safe: 'html'"></div>
                        <hr class="button-separator" *ngIf="!startingPrice" />
                        <div id="starting-price" *ngIf="startingPrice && configuratorExists">
                            <span class="price-text">
                                Starting Price: <span class="price-number">{{ startingPrice }}</span>
                            </span>
                        </div>
                    </div>
                    <div id="product-button-container">
                        <div class="d-flex flex-row w-100" *ngIf="isSolutionProduct">
                            <a
                                [href]="configuratorUrl"
                                class="w-50 configurator-customize-button-spacing"
                                *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product?.mpn)"
                            >
                                <button-component
                                    [buttonOptions]="{
                                        text: 'Customize',
                                        type: 'primary',
                                        iconPosition: 'right',
                                        iconName: 'tool',
                                        class: 'w-100'
                                    }"
                                ></button-component>
                            </a>
                            <a class="w-50 product-quote-button-spacing">
                                <button-component
                                    (click)="openBootstrapMarketoDialog(marketoIDLong, product)"
                                    data-cy="exx-product-page-get-quote-button"
                                    [buttonOptions]="{
                                        text: 'Get a quote',
                                        type: 'secondary',
                                        iconPosition: 'right',
                                        iconName: 'message-square',
                                        class: 'w-100'
                                    }"
                                ></button-component>
                            </a>
                        </div>

                        <div id="product-list-cart-actions" class="fx-row fx-row-v-center" *ngIf="!isSolutionProduct">
                            <span *ngIf="!(configuratorExists && !configuratorService?.isBlacklisted(product?.mpn))" class="mr-3">
                                <input #wideQuantity value="{{ selectedQuantity }}" type="hidden" />
                                <mat-select [(value)]="defaultSelectValue" disableOptionCentering>
                                    <mat-option *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="n" (click)="updateQuantity(n)">
                                        {{ n }}
                                    </mat-option>
                                    <mat-option class="dialog-close" [mat-dialog-close]="">
                                        <a class="dialog-close" routerLink="/Contact-Us">Bulk</a></mat-option
                                    >
                                </mat-select>
                            </span>
                            <span class="d-flex flex-row p-0 w-100">
                                <a
                                    [href]="configuratorUrl"
                                    class="w-100 configurator-customize-button-spacing"
                                    *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product?.mpn)"
                                >
                                    <button-component
                                        [buttonOptions]="{
                                            text: 'Customize',
                                            type: 'primary',
                                            iconPosition: 'right',
                                            iconName: 'tool',
                                            class: 'w-100'
                                        }"
                                    ></button-component>
                                </a>
                                <button-component
                                    (click)="openBootstrapMarketoDialog(marketoIDLong, product)"
                                    class="w-100"
                                    data-cy="exx-product-page-get-quote-button"
                                    [buttonOptions]="{
                                        text: 'Get a quote',
                                        type: configuratorExists && !configuratorService?.isBlacklisted(product?.mpn) ? 'tertiary' : 'primary',
                                        iconPosition: 'right',
                                        iconName: 'message-square',
                                        class: 'w-100'
                                    }"
                                ></button-component>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isVisible" id="floating-text-button" class="fx-row fx-row-v-center fx-row-h-space-between align-items-center">
            <span *ngIf="!isSolutionProduct">
                <input #narrowQuantity value="{{ selectedQuantity }}" type="hidden" />
                <mat-select [(value)]="defaultSelectValue" disableOptionCentering>
                    <mat-option *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="n" (click)="updateQuantity(n)">
                        {{ n }}
                    </mat-option>
                    <mat-option class="dialog-close" [mat-dialog-close]=""> <a class="dialog-close" routerLink="/Contact-Us">Bulk</a></mat-option>
                </mat-select>
            </span>
            <a
                [href]="configuratorUrl"
                class="w-100 configurator-customize-button-spacing m-0"
                *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product?.mpn)"
            >
                <button-component
                    class="w-100"
                    *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product?.mpn)"
                    [buttonOptions]="{
                        text: 'Customize',
                        routerLink: configuratorUrl,
                        type: 'primary',
                        iconPosition: 'right',
                        unsetWidth: true,
                        iconName: 'tool',
                        class: 'w-100'
                    }"
                ></button-component>
            </a>
            <button-component
                class="w-100"
                data-cy="product-page-get-a-quote-button"
                (click)="openBootstrapMarketoDialog(marketoIDLong, product)"
                [buttonOptions]="{
                    text: 'Get a Quote',
                    routerLink: configuratorUrl,
                    type: 'secondary',
                    iconPosition: 'right',
                    iconName: 'message-square',
                    class: 'w-100'
                }"
            ></button-component>
        </div>
    </div>
</section>

<ng-template #templateModal>
    <div id="modal-parent">
        <div class="close-icon" aria-label="Close" (click)="bsModalRef.hide()">
            <span class="d-flex align-items-center">
                <i-feather name="x"></i-feather>
            </span>
        </div>
        <div id="main-image-frame-container">
            <div class="test">
                <div id="main-image-frame">
                    <div class="slider-container" [ngStyle]="{ transform: 'translateX(-' + amount * 650 + 'px)' }">
                        <div *ngFor="let imageSlide of product?.imageUrls" class="img-container"><img [src]="imageSlide" /></div>
                    </div>
                </div>
            </div>
        </div>
        <app-product-page-image-tile
            [(amount)]="amount"
            [slidesPerView]="4"
            [productImages]="product?.imageUrls"
            [(translateAmount)]="translateAmount"
            [widthPerSlideImage]="66"
        >
        </app-product-page-image-tile>
    </div>
</ng-template>
