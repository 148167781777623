import { Component, Inject, OnInit } from '@angular/core';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { MetaService } from 'lib/services/meta.service';

const scriptName = 'cms-contact-us.component';

@Component({
    selector: 'app-cms-contact-us',
    templateUrl: './cms-contact-us.component.html',
    styleUrls: ['./cms-contact-us.component.scss'],
})
export class CmsContactUsComponent extends CmsPartialComponentClass implements OnInit {
    // Properties: public
    entry: any = {};
    formId: any;
    interest: any;

    // Dependencies
    metaService: MetaService;

    constructor(
        c: CmsService,
        public bootstrapMarketoService: BootstrapMarketoService,
        m: MetaService,
        @Inject('environment') environment: any
    ) {
        super({
            dependencies: {
                cmsService: c,
                bootstrapMarketoService: bootstrapMarketoService,
                environment,
                metaService: m,
            },
        });
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('contact_us');
            const id = get(this.entry, 'marketo_form.id');
            if (!id) {
                throw new Error('Marketo form ID is missing on CMS entry: contentTypeId: contact_us');
            }
            this.formId = `mktoForm_${id}`;
            this.interest = `${this.environment.urls.base}/Contact-Us`;
        } catch (err) {
            console.error(...new ExxComError(680273, scriptName, err).stamp());
        }
    }
}
