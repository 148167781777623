import { Injectable, Inject } from '@angular/core';

import { Account } from 'lib/services/account/account.class';
import { AccountService } from 'lib/services/account/account.service';
import { ApiService } from 'lib/services/api.service';
import { AuthService } from 'lib/services/auth.service';
import { Cart } from 'lib/services/cart/cart.class';
import { CartItem, CartResponse, CartShippingAddress } from 'lib/services/cart/cart.interface';
import { contains } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { find, get, isEmpty } from 'lodash';
import { isBrowser } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'cart.service';

let accountService: AccountService;
let apiService: ApiService;
let authService: AuthService;
let environment: any;
let routerService: RouterService;
let sessionService: SessionService;

@Injectable()
export class CartService {
    cart: Cart = null;
    confirmationCart: Cart = null;
    maxItemQuantity: number = 10;

    constructor(@Inject('environment') e: any, ac: AccountService, ap: ApiService, au: AuthService, r: RouterService, s: SessionService) {
        try {
            accountService = ac;
            apiService = ap;
            authService = au;
            environment = e;
            routerService = r;
            sessionService = s;
        } catch (err) {
            console.error(...new ExxComError(549823, scriptName, err).stamp());
        }
    }

    // Initializers

    /**
     * @function init
     * @description The cart service is initialized in the following case:
     * - in app-initializers when the site first loads
     */
    async init(): Promise<void> {
        try {
            if (!isBrowser()) {
                return;
            }
            (await sessionService.isValidSession()) ? await this.initLoggedIn() : await this.initLoggedOut();
            authService.logout$.subscribe(() => this.onLogout());
            routerService.onRouteChange('cartService', async () => {
                if (!routerService.isCheckout) {
                    await this.clearOutsideCheckout();
                }
            });
        } catch (err) {
            console.error(...new ExxComError(893884, scriptName, err).stamp());
        }
    }

    /**
     * @function initLoggedIn
     * @description Called when the page is refreshed if a customer is logged in.
     * initLoggedIn is only called via init, which is only called in
     * app-initializers when the site first loads.
     * Logged-in Flow
     *   refresh page
     *   init called
     *   get id from account
     *     if account has id
     *       get cart
     *     if account does not have id or data is empty
     *       create new cart
     *     set cart email to account email
     *     set account cart to cart id
     *   if browser id
     *     delete browser id
     *     if browser id != cart id
     *       delete browser id
     */
    private async initLoggedIn(): Promise<void> {
        try {
            this.cart = new Cart({ dependencies: { environment } });
            const account: Account = get(accountService, 'account');
            const _id = get(account, 'cart');
            let res: CartResponse;
            if (_id) {
                res = await this.get({ _id });
            }
            if (!_id || (res.success && isEmpty(res.data))) {
                res = await this.create();
            }
            if (!res.success) {
                return;
            }
            this.cart.init(res);
            await this.updateEmail(get(account, 'email'));
            await accountService.updateCartId(this.cart._id);
            const browserId = this.getIdFromBrowser();
            if (browserId) {
                if (browserId != this.cart._id) {
                    await this.delete(browserId);
                }
                this.removeIdFromBrowser();
            }
        } catch (err) {
            console.error(...new ExxComError(692878, scriptName, err).stamp());
        }
    }

    /**
     * @function initLoggedOut
     * @description Called when the page is refreshed if a customer is not logged
     * in. initLoggedOut is only called via init, which is only called in
     * app-initializers when the site first loads.
     * Logged-out Flow
     *   refresh page
     *   init called
     *   get id from browser
     *   if browser has id
     *     retrieve cart
     *     get cart email
     *   if browser does not have id, or data is empty, or cart has email (belongs to account)
     *     create new cart
     *     save cart id in browser
     */
    private async initLoggedOut(): Promise<void> {
        try {
            this.cart = new Cart({ dependencies: { environment } });
            const _id = this.getIdFromBrowser();
            let res: CartResponse;
            let email: string;
            if (_id) {
                res = await this.get({ _id });
                email = get(res, 'data.email');
            }
            if (!_id || email || (res.success && isEmpty(res.data))) {
                res = await this.create();
            }
            if (!res.success) {
                return;
            }
            this.cart.init(res);
            this.saveIdInBrowser(this.cart._id);
        } catch (err) {
            console.error(...new ExxComError(310837, scriptName, err).stamp());
        }
    }

    /**
     * @function onLogin
     * @description Called on log in after the account has been retrieved in,
     * - auth-login.component
     * - auth-register.component
     * On Login Flow
     *   router navigates, page does not refresh
     *     asynchronously retrieve both browser and account carts
     *     if neither cart exists
     *       create one
     *     if browser cart exists and account cart does not exist
     *       use browser cart
     *     if browser cart does not exist and account cart exists
     *       use account cart
     *     if both carts exist
     *       if browser cart is empty
     *         use account cart
     *       if browser cart is not empty
     *         use browser cart
     *     get the id of the cart that will be used
     *     if browser has id
     *       remove it from browser
     *     if there is a browser cart and its id is not same as id to be used
     *       remove browser cart from db
     *     if there is an account cart and its id is not the same as id to be use
     *       remove account cart from db
     *     if the account cart id is not the same as the id to be used
     *       update the account with the id to be used
     *     if email on cart to be used is not the same as email on account
     *       update cart email with account email
     */
    async onLogin(): Promise<void> {
        try {
            const browserId = this.getIdFromBrowser();

            const account = get(accountService, 'account');
            const accountId = get(account, 'cart');

            // Get both carts

            const promises: Promise<Cart>[] = [];

            if (this.cart._id == browserId) {
                promises.push(Promise.resolve(this.cart));
            } else {
                promises.push(this.getDetached({ _id: browserId }));
            }

            if (this.cart._id == accountId) {
                promises.push(Promise.resolve(this.cart));
            } else {
                promises.push(this.getDetached({ _id: accountId }));
            }

            const carts: Cart[] = await Promise.all(promises);

            const browserCart: Cart = carts[0];
            const accountCart: Cart = carts[1];

            const browserCartId: string = get(browserCart, '_id');
            const accountCartId: string = get(accountCart, '_id');

            // Select cart to be used in account

            if (!browserCart && !accountCart) {
                const res: CartResponse = await this.create();
                if (!res.success) {
                    throw res;
                }
                this.cart.init(res);
            } else {
                if (browserCart && !accountCart) {
                    this.cart = browserCart;
                } else if (!browserCart && accountCart) {
                    this.cart = accountCart;
                } else if (browserCart && accountCart) {
                    if (browserCart.isEmpty) {
                        this.cart = accountCart;
                    } else {
                        this.cart = browserCart;
                    }
                }
            }

            // Follow-up settings

            const _id = this.cart._id;

            if (browserId) {
                this.removeIdFromBrowser();
            }

            if (browserCart && browserCartId != _id) {
                await this.delete(browserCart._id);
            }
            if (accountCart && accountCartId != _id) {
                await this.delete(accountCart._id);
            }

            if (accountId != _id) {
                await accountService.updateCartId(_id);
            }

            const email = get(account, 'email');
            if (this.cart.email != email) {
                await this.updateEmail(email);
            }
        } catch (err) {
            console.error(...new ExxComError(592883, scriptName, err).stamp());
        }
    }

    /**
     * @function onLogout
     * @description Subscribed to when the cart service is initialized, it is
     * called when the authService logout$ Subject is invoked.
     *
     * Since the browser ID is removed and there is no longer a cart saved in the
     * database associated with the browser after a customer logs in, when a
     * customer logs out, a new cart is created.
     *
     * If they previously added items to their cart and logged in, logged out, did
     * not add anything to their browser cart, and then log back in, they'll get
     * their previous account cart with the items they added before. If they added
     * anything to their browser cart, their browser cart will be turned into
     * their account cart and the account cart deleted.
     */
    private async onLogout(): Promise<void> {
        try {
            const res = await this.create();
            if (!res.success) {
                return;
            }
            this.cart.init(res);
            this.saveIdInBrowser(this.cart._id);
        } catch (err) {
            console.error(...new ExxComError(394881, scriptName, err).stamp());
        }
    }

    /**
     * @function reinit
     * @param {Boolean} callOnFirstRoute
     * @description At certain places in the Single-page Application, the cart
     * needs to be retrieved again, after it was initialized during page load, in
     * case there were any price changes, because those areas display cart item
     * prices.
     * - Cart page
     * - Checkout pages
     * - Header cart menu
     * For the places where reinit is called on page components (cart, checkout),
     * there is no need to call it if it is the first route (i.e., a full page
     * refresh) because the cart is initialized when the page app fully loads. It
     * does need to be called on subsequent navigation to and from the page
     * components because the cart is only initialized when the app fully loads.
     * If reinit is called in a non-page-load context, like when toggling the
     * header cart menu, then it needs to be called regardless of whether it is
     * the first route or not.
     */
    async reinit(
        { callOnFirstRoute }: { callOnFirstRoute?: boolean } = {
            callOnFirstRoute: false,
        }
    ): Promise<void> {
        try {
            if (!callOnFirstRoute && routerService.isFirstRoute) {
                return;
            }
            const _id = get(accountService, 'account.cart') || this.getIdFromBrowser();
            if (!_id) {
                return;
            }
            const res = await this.get({ _id });
            if (!res.success) {
                return;
            }
            this.cart.init(res);
        } catch (err) {
            console.error(...new ExxComError(720993, scriptName, err).stamp());
        }
    }

    // Core API

    private async create(): Promise<CartResponse> {
        try {
            const values = { webstore: environment.siteAbbr };
            const res: CartResponse = await apiService.post('carts/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            const suppress = contains(['Unknown Error', 'cannotconnect'], get(err, 'statusText', ''));
            if (!suppress) {
                console.error(...new ExxComError(679299, scriptName, err).stamp());
            }
            return { success: false } as CartResponse;
        }
    }

    async get({
        _id = null,
        refNumber = null,
        includeFinished = false,
    }: {
        _id?: string;
        refNumber?: string;
        includeFinished?: boolean;
    }): Promise<CartResponse> {
        try {
            if (!_id && !refNumber) {
                return null;
            }
            const filters: any = environment.siteAbbr == 'exc' ? {} : { webstore: environment.siteAbbr };
            if (_id) {
                filters._id = _id;
            } else if (refNumber) {
                filters.refNumber = refNumber;
            }
            if (!includeFinished) {
                filters.status = { $ne: 'Finished' };
            }
            const res: CartResponse = await apiService.get(['carts/find', `?filters=${JSON.stringify(filters)}`]);
            if (!res) {
                throw new Error('No response');
            }
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(839084, scriptName, err).stamp());
            return { success: false, error: err } as CartResponse;
        }
    }

    async update(action: string, values: any): Promise<void> {
        try {
            const getUrl = (_id: string) => `carts/update/${_id}/${action}`;
            let res = await apiService.post(getUrl(this.cart._id), values);
            if (!res.success) {
                throw res;
            } else if (isEmpty(res.data) && action == 'updateCart') {
                return;
            } else if (isEmpty(res.data)) {
                // If cart does not exist
                res = await this.create(); // Create it
                if (!res.success) {
                    throw res;
                }
                this.saveIdInBrowser(this.cart._id); // Save the new ID
                res = await apiService.post(getUrl(res.data._id), values); // Do the update
                if (!res.success) {
                    throw res;
                }
            }
            this.cart.init(res);
        } catch (err) {
            console.error(...new ExxComError(395994, scriptName, err).stamp());
        }
    }

    private async delete(id: string): Promise<void> {
        try {
            return await apiService.delete(`carts/delete/${id}`);
        } catch (err) {
            console.error(...new ExxComError(203888, scriptName, err).stamp());
        }
    }

    // Get API

    async getDetached({ _id, refNumber, searching }: { _id?: string; refNumber?: string; searching?: boolean }) {
        try {
            if (!_id && !refNumber) {
                return null;
            }
            const res = await this.get({
                _id,
                refNumber,
                includeFinished: true,
            });
            if (res.error) {
                throw res;
            }
            if (isEmpty(res.data)) {
                return null;
            }
            if (searching) {
                return res.data[0];
            } // we are searching for just one cart
            const cart = new Cart({ dependencies: { environment } });
            cart.init(res);
            return cart;
        } catch (err) {
            console.error(...new ExxComError(502913, scriptName, err).stamp());
        }
    }

    async getAll({ query }: { query?: any }): Promise<CartResponse> {
        try {
            let filters: any = {};
            if (query) {
                filters = { ...filters, ...query };
            }
            const res: CartResponse = await apiService.get(['carts/find', `?filters=${JSON.stringify(filters)}`]);
            return res;
        } catch (err) {
            console.error(...new ExxComError(502986, scriptName, err).stamp());
        }
    }

    async getConfirmationCart(refNumber: string): Promise<Cart> {
        try {
            const confirmationCartrefNumber = get(this, 'confirmationCart.refNumber');
            if (refNumber == confirmationCartrefNumber) {
                return this.confirmationCart;
            }
            this.confirmationCart = await this.getDetached({ refNumber });
            return this.confirmationCart;
        } catch (err) {
            console.error(...new ExxComError(304999, scriptName, err).stamp());
        }
    }

    // Update API

    async add(productId: string, quantity: number | string): Promise<void> {
        try {
            if (typeof quantity == 'string') {
                quantity = parseInt(quantity);
            }
            const item = get(this, 'cart.items', []).find((item: CartItem) => item.product._id == productId);
            const currentQuantity = get(item, 'quantity', 0);
            const totalQuantity = currentQuantity + quantity;
            if (totalQuantity > this.maxItemQuantity) {
                quantity = this.maxItemQuantity;
            }
            if (this.cart.hasItem(productId)) {
                await this.updateQuantity(productId, totalQuantity);
            } else {
                await this.update('add', {
                    productId,
                    quantity: totalQuantity,
                });
            }
        } catch (err) {
            console.error(...new ExxComError(398583, scriptName, err).stamp());
        }
    }

    async remove(productId: string): Promise<void> {
        try {
            await this.update('remove', { productId });
        } catch (err) {
            console.error(...new ExxComError(398583, scriptName, err).stamp());
        }
    }

    async updateQuantity(productId: string, quantity: number | string): Promise<void> {
        try {
            if (typeof quantity == 'string') {
                quantity = parseInt(quantity);
            }
            if (quantity > this.maxItemQuantity) {
                quantity = this.maxItemQuantity;
            }
            await this.update('updateQuantity', { productId, quantity });
        } catch (err) {
            console.error(...new ExxComError(203988, scriptName, err).stamp());
        }
    }

    async updateShippingAddress(shippingAddress: CartShippingAddress): Promise<void> {
        try {
            const fields = ['address1', 'city', 'state', 'zip', 'country'];
            const cartAddress = get(this, 'cart.shippingAddress', {});
            const toUpdate = {};
            fields.forEach((field: string) => {
                const newValue = shippingAddress[field];
                if (cartAddress[field] != newValue) {
                    toUpdate[field] = newValue;
                }
            });
            if (!isEmpty(toUpdate)) {
                await this.update('updateShippingAddress', toUpdate);
            }
        } catch (err) {
            console.error(...new ExxComError(399492, scriptName, err).stamp());
        }
    }

    async updateShippingCost(shippingCost: number | string): Promise<void> {
        try {
            if (typeof shippingCost != 'number') {
                shippingCost = parseFloat(shippingCost);
            }
            if (get(this, 'cart.summary.shipping') === shippingCost) {
                return;
            }
            await this.update('updateShippingCost', { shippingCost });
        } catch (err) {
            console.error(...new ExxComError(502844, scriptName, err).stamp());
        }
    }

    async updateEmail(email: string): Promise<void> {
        try {
            if (get(this, 'cart.email') != email) {
                await this.update('updateEmail', { email });
            }
        } catch (err) {
            console.error(...new ExxComError(502844, scriptName, err).stamp());
        }
    }

    async updateCart(updatedCart: any) {
        try {
            if (get(this, 'cart') != updatedCart) {
                await this.update('updateCart', { updatedCart });
            }
        } catch (err) {
            console.error(...new ExxComError(502844, scriptName, err).stamp());
        }
    }

    async updateDiscountData(updatedDiscountData: any): Promise<void> {
        try {
            if (get(this, 'cart.discountData') != updatedDiscountData) {
                await this.update('updateDiscountData', { discountData: updatedDiscountData });
            }
        } catch (err) {
            console.error(...new ExxComError(502846, scriptName, err).stamp());
        }
    }

    async updateDiscountSummary(updatedDiscountSummary: any): Promise<void> {
        try {
            if (get(this, 'cart.discountSummary') != updatedDiscountSummary) {
                await this.update('updateDiscountSummary', { discountSummary: updatedDiscountSummary });
            }
        } catch (err) {
            console.error(...new ExxComError(502845, scriptName, err).stamp());
        }
    }

    async clearOutsideCheckout(): Promise<void> {
        try {
            if (get(this, 'cart.summary.shipping') != 0) {
                await this.updateShippingCost(0);
            }
            if (!find(get(this, 'cart.shippingAddress', {}), (v: string) => v != '')) {
                return;
            }
            await this.updateShippingAddress({
                address1: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            });
        } catch (err) {
            console.error(...new ExxComError(720931, scriptName, err).stamp());
        }
    }

    /**
     * @function finalize
     * @param {String} refNumber
     * @description
     * - Removes cart ID from customer document in case subsequent error
     * - Adds refNumber to cart in DB, sets cart status to "Finished"
     * - Creates a new cart
     * - Saves new cart ID on customer
     */
    async finalize(refNumber: string): Promise<void> {
        try {
            await accountService.updateCartId(null);
            await this.update('finalize', { refNumber });
            const res: CartResponse = await this.create();
            if (!res.success) {
                throw res;
            }
            this.cart.init(res);
            await accountService.updateCartId(this.cart._id);
        } catch (err) {
            console.error(...new ExxComError(310847, scriptName, err).stamp());
        }
    }

    // Browser ID API

    private saveIdInBrowser(id: string): void {
        try {
            if (!isBrowser() || !id) {
                return;
            }
            localStorage.setItem('cartId', id);
        } catch (err) {
            console.error(...new ExxComError(209398, scriptName, err).stamp());
        }
    }

    private getIdFromBrowser(): string {
        try {
            if (!isBrowser()) {
                return;
            }
            const id: string = localStorage.getItem('cartId');
            return id && id != 'null' ? id : null;
        } catch (err) {
            console.error(...new ExxComError(209398, scriptName, err).stamp());
            return null;
        }
    }

    private removeIdFromBrowser(): void {
        try {
            if (!isBrowser()) {
                return;
            }
            localStorage.removeItem('cartId');
        } catch (err) {
            console.error(...new ExxComError(720993, scriptName, err).stamp());
        }
    }
}
