import { SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerData } from '../customer/customer.interface';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { initServiceClassBoolean } from 'lib/tools';
import {
    OrderAddresses,
    OrderEasyPostTracker,
    OrderLine,
    OrderData,
    OrderShippingMethod,
    OrderStripePayment,
    DiscountData,
    DiscountSummary,
} from 'lib/services/order/order.interface';

const scriptName = 'order.class';

const defaults = {
    addresses: {
        billing: null,
        shipping: null,
    },
    easyPostTracker: {
        id: '',
        trackingCode: '',
        url: '',
    },
    shippingMethod: {
        carrier: null,
        deliveryDate: null,
        name: null,
        nsid: null,
    },
    stripePayment: {
        canceled: false,
        captured: false,
        clientSecret: '',
        description: '',
        idempotencyKey: '',
        intentId: '',
        card: {
            expMonth: '',
            expYear: '',
            id: '',
            last4: '',
        },
    },
    updatedAddresses: {
        billing: [],
        shipping: [],
    },
};

export class Order {
    // Schema

    _id: string = null;
    addresses: OrderAddresses = defaults.addresses;
    amountShipping: number = 0;
    amountSubtotal: number = 0;
    amountTax: number = 0;
    amountTotal: number = 0;
    cart: string = null; // ExxCom cart ID
    createdAt: Date = null;
    customer: CustomerData = null;
    customerName: string = null;
    customerNsid: string = null;
    discountData: DiscountData = null;
    discountSummary: DiscountSummary = null;
    modifiedLines: OrderLine[] = [];
    easyPostTracker: OrderEasyPostTracker = defaults.easyPostTracker;
    email: string = null;
    freightTerms: string = null;
    internalMessage: string = null;
    lines: OrderLine[] = [];
    location: string = null;
    margin: number = 0;
    marginPercent: number = 0;
    nsDateCreated: Date = null;
    nsid: string = null;
    paymentTerms: string = null;
    poNumber: string = null;
    refNumber: string = null;
    salesOrderId: string = null;
    salesRep: string = null;
    shipDate: Date = null;
    shipDateActual: Date = null;
    shipEarly: boolean = null;
    shippingMethod: OrderShippingMethod = defaults.shippingMethod;
    signatureRequired: boolean = null;
    status: string = null;
    stripePayment: OrderStripePayment = defaults.stripePayment;
    trackingNumbers: string = null;
    type: string = null;
    updated: string[] = [];
    updatedAddresses: { billing?: string[]; shipping?: string[] } = defaults.updatedAddresses;
    updatedAt: Date;
    updatedLines: string[] = [];
    user: string = null;
    webstore: string = null;

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: OrderData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: OrderData) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.addresses = data.addresses || this.addresses || defaults.addresses;
            this.amountShipping = data.amountShipping || this.amountShipping || 0;
            this.amountSubtotal = data.amountSubtotal || this.amountSubtotal || 0;
            this.amountTax = data.amountTax || this.amountTax || 0;
            this.amountTotal = data.amountTotal || this.amountTotal || 0;
            this.cart = data.cart || this.cart || null;
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;
            if (typeof data.customer == 'string') {
                this.customer._id = (data.customer || null) as string;
            } else {
                this.customer = data.customer || (typeof this.customer != 'string' && this.customer) || null;
            }
            this.customerName = data.customerName || this.customerName || '';
            this.customerNsid = data.customerNsid || this.customerNsid || null;
            this.easyPostTracker = data.easyPostTracker || this.easyPostTracker || defaults.easyPostTracker;
            this.email = data.email || this.email || null;
            this.freightTerms = data.freightTerms || this.freightTerms || null;
            this.internalMessage = data.internalMessage || this.internalMessage || null;
            this.lines = data.lines || this.lines || [];
            this.location = data.location || this.location || null;
            this.margin = data.margin || this.margin || 0;
            this.marginPercent = data.marginPercent || this.marginPercent || 0;
            this.nsDateCreated = data.nsDateCreated ? new Date(data.nsDateCreated) : this.nsDateCreated || null;
            this.nsid = data.nsid || this.nsid || null;
            this.paymentTerms = data.paymentTerms || this.paymentTerms || null;
            this.poNumber = data.poNumber || this.poNumber || null;
            this.refNumber = data.refNumber || this.refNumber || null;
            this.salesOrderId = data.salesOrderId || this.salesOrderId || null;
            this.salesRep = data.salesRep || this.salesRep || null;
            this.shipDate = data.shipDate ? new Date(data.shipDate) : this.shipDate || null;
            this.shipDateActual = data.shipDateActual ? new Date(data.shipDateActual) : this.shipDateActual || null;
            this.shipEarly = initServiceClassBoolean('shipEarly', data.shipEarly, this);
            this.shippingMethod = data.shippingMethod || this.shippingMethod || defaults.shippingMethod;
            this.signatureRequired = initServiceClassBoolean('signatureRequired', data.signatureRequired, this);
            this.status = data.status || this.status || null;
            this.stripePayment = data.stripePayment || this.stripePayment || defaults.stripePayment;
            this.trackingNumbers = data.trackingNumbers || this.trackingNumbers || null;
            this.type = data.type || this.type || null;
            this.updated = data.updated || this.updated || [];
            this.updatedAddresses = data.updatedAddresses || this.updatedAddresses || defaults.updatedAddresses;
            this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : this.updatedAt || null;
            this.updatedLines = data.updatedLines || this.updatedLines || [];
            this.user = data.user || this.user || null;
            this.webstore = data.webstore || this.webstore || null;

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(520371, scriptName, err).stamp());
        }
    }
}
